



















import { defineComponent, PropType } from "@vue/composition-api";

import VSelect from "@/app/components/Base/VSelect.vue";
import { User } from "@/shared/types/schema.types";

export default defineComponent({
  name: "UserSelect",
  components: { VSelect },
  props: {
    value: {
      type: String as PropType<string | null>,
      default: () => null,
    },
    items: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
  },
});
